import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import {
  CellValue,
  Column,
  CustomTable,
  DynamicDialogProps,
  TableSettings,
} from "components/Table/Table";
import ContentLayout from "components/layouts/ContentLayout";
import QueryParams from "models/queryParams.model";
import Product from "models/resources/product.model";
import useProductTable from "pages/account/Product/Table/useProductTable";
import { getData } from "services/requests/listData";

interface Props {
  productIds: number[];
}

const ProductsTable = ({ productIds }: Props) => {
  const { t } = useTranslation();
  const { productsData, setData, setProductsData } = useProductTable();
  const getProducts = async (queryParams: QueryParams, setDataLoading: any) => {
    return await getData(
      setProductsData,
      "products",
      {
        ...queryParams,
        // @ts-ignore
        filter: { ...queryParams.filter, dateRange: null, ids: productIds },
      },
      setDataLoading,
    );
  };

  const columns: Column<Product>[] = [
    {
      label: t("Name"),
      name: "name",
      getData: (data): CellValue => data.name,
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "name" },
    },
    {
      label: t("Product name"),
      name: "productName",
      getData: (data): CellValue => data.display_name,
      show: () => {
        return true;
      },
    },

    {
      label: t("Company"),
      name: "company",
      getData: (data): CellValue => (
        <Link
          to={{
            pathname: `/account/companies/${data.company_id}`,
          }}
        >
          {data.company.display_name || data.company.name}
        </Link>
      ),
      show: () => {
        return true;
      },
      sort: { isSortable: true, field: "company_id" },
      width: "15%",
      minWidth: "200px",
    },
    {
      label: t("Currency"),
      name: "currency",
      getData: (data): CellValue => data.currency,
      show: () => {
        return true;
      },
    },
    {
      label: t("Price"),
      name: "price",
      getData: (data): CellValue => Number(data.price).toFixed(2),
      show: () => {
        return true;
      },
    },
    {
      label: t("Type"),
      name: "type",
      getData: (data): CellValue => t(data.product_type),
      show: () => {
        return true;
      },
    },
    {
      label: t("Edit"),
      name: "edit",
      getData: (data): CellValue => data.id,
      show: () => {
        return true;
      },
      width: "5%",
      align: "center",
    },
  ];

  const tableOptions: TableSettings = {
    pagination: true,
    page: 0,
    rowsPerPage: 25,
    field: "id",
    order: "asc",
  };

  const dynamicDialogProps: DynamicDialogProps = {
    title: "Add product",
    resource: "product",
    call: "products",
    styles: () => "flexHalfForm",
  };

  return (
    <CustomTable
      title={t("Products")}
      tableData={productsData}
      fetchPaginatedData={getProducts}
      setTableData={setProductsData}
      columns={columns}
      resource="product"
      call="vending/products"
      tableOptions={tableOptions}
      isNestedTable={true}
      exportProp={"products"}
      dynamicDialogProps={dynamicDialogProps}
      isDropDown={true}
      notCSV={true}
    />
  );
};

export default ProductsTable;
